import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const Footer = ({ data }) => (
    <footer className="footer">
        <div className="content has-text-centered">
            <p>{data.site.siteMetadata.footer}</p>
        </div>
        
    </footer>
)
export default function SiteFooter(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                site {
                    siteMetadata {
                    footer
                    }
                }
                }
            `}
            render={data => <Footer data={data} {...props} />}
        />
    )
}

Footer.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                footer: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
}