import React from 'react'
import MainLayout from '../layouts/mainlayout'

function ErrorPage() {

    return (
        <MainLayout>
            <h1>Página no encontrada</h1>
        </MainLayout>
    )
}

export default ErrorPage;