import React, { useState, useEffect }  from 'react'
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';

function Navbar() {

    const [isActive, setIsActive] = useState(false);
    const [isTransparent, setIsTransparent] = useState(true);

    const logoWhite = require('../images/logo-white.png')
    const logoBlack = require('../images/logo-black.png')

    function toggleNavState() {
        setIsActive(!isActive)
    }

    function handleScrollorResize(event) {
        setIsTransparent(window.scrollY < 200 && window.innerWidth >= 1024)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScrollorResize);
        window.addEventListener('resize', handleScrollorResize);
        return () => {
          window.removeEventListener("scroll", handleScrollorResize);
          window.removeEventListener('resize', handleScrollorResize);
        };
    });

    return (
        <nav className={`navbar is-fixed-top ${isTransparent ? "navbar-transparent" : ""}`}>
            <div className="navbar-brand">
                <a className={`navbar-item ${isTransparent ? "navbar-item-transparent" : ""} is-family-secondary is-size-5`} to="/">
                    <img src={isTransparent ? logoWhite : logoBlack} width="30" height="30" />
                </a>
                <div className={
                    isActive
                        ? 'navbar-burger burger is-active'
                        : 'navbar-burger burger'
                    } data-target="navbar-datatarget" onClick={toggleNavState}
                    role="button">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div id="navbar-datatarget" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className="navbar-start spaced-text">
                    <a className={`navbar-item ${isTransparent ? "navbar-item-transparent" : ""} is-uppercase`} onClick={() => scrollTo('#inicio')}>
                        Inicio
                    </a>
                    <a className={`navbar-item ${isTransparent ? "navbar-item-transparent" : ""} is-uppercase`} onClick={() => scrollTo('#historia')}>
                        Nuestra Historia
                    </a>
                    <a className={`navbar-item ${isTransparent ? "navbar-item-transparent" : ""} is-uppercase`} onClick={() => scrollTo('#countdown')}>
                        Fecha
                    </a>
                    <a className={`navbar-item ${isTransparent ? "navbar-item-transparent" : ""} is-uppercase`} onClick={() => scrollTo('#place')}>
                        Lugar
                    </a>
                    <a className={`navbar-item ${isTransparent ? "navbar-item-transparent" : ""} is-uppercase`} onClick={() => scrollTo('#galeria')}>
                        Galeria
                    </a>
                    
                </div>

                {

                /*
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button className="button is-primary" onClick={() => scrollTo('#rsvp')}>
                                <strong>RSVP</strong>
                            </button>
                        </div>
                    </div>
                </div>
                    */
                }
            </div>
        </nav>
    )
}


export default Navbar;