import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

function MainLayout(props) {

    return(
        <>
            <Navbar />
                {props.children}
            <Footer />
        </>
    )
}

export default MainLayout;